import React, { Component } from "react";
import { Typeahead, Token } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "./AssortmentTypeAhead.css";

export class AssortmentTypeAhead extends Component {
    constructor(props) {
        super(props);
      
        this.state = { selected: "" };
        this.typeahead = React.createRef();
    }

    IsWhiteListed(item) {
        if (item.url) {
            return false;
        }
        // Fetch list of allowed types from backend
        if (item.type === "ProductModel") {
            return true;
        }
        if (item.type === "CustomerModel") {
            return true;
        }
        return false;
    }
    clear() {
        this.typeahead.current.clear();
    }
    setSelected(selected) {
        this.props.change(selected);
        this.setState({ selected });
    }

    render() {
        const opts = this.props.data.filter(row => this.IsWhiteListed(row.item)).map(d => {
            const opt = {};
            opt.id = d.item.id;
            opt.label = d.item.name;
            opt.level = d.node.level;
            if (d.item.typedParents.length > 0) {
                opt.type = d.item.typedParents[0].value;
            }
            else {
                opt.type = d.item.type;
            }
            return opt;
        });

        // Sorting based on level
        opts.sort(function (a, b) {
            if (a.level < b.level) {
                return -1;
            }
            if (a.level > b.level) {
                return 1;
            }
            return 0;
        });
        return (
            <Typeahead
                id="dwaTypeahead"
                ref={this.typeahead}
                onChange={(selected) => {
                    this.setSelected(selected);
                }}
                placeholder="Filter Products. Try models or product lines."
                options={opts}
                selectHintOnEnter={true}
                clearButton={true}
                minLength={2}
                multiple
                emptyLabel={"No results found"}
                renderMenuItemChildren={(option) => (
                    <div>
                        {option.label}
                        <div>
                            <small>{option.type}</small>
                        </div>
                    </div>
                )}
                renderToken={(option, props, index) => {
                    return (
                        <Token
                            key={index}
                            onRemove={props.onRemove}
                            className="al-token"
                            option={option}>
                            {option.label}
                        </Token>
                    );
                }}
            />
        );
    }
}