import React, { Component } from 'react';
import DOMpurify from 'dompurify';
import { getAssetBlob } from '../../api/AssetApi';
import { APIContext } from '../../api/api';

export class ExtrasContent extends Component { 
    constructor(props) {
        super(props);
        this.content = props.content;
    }
    async handleAssetClick(assetLink) {

        let url = assetLink;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'file_download',
            'file_product_line': this.content.productLine,
            'file_lang': this.content.language,
            'file_name': this.content.name,
            'file_extension': 'pdf',
            'file_category': this.content.productLine,
            'file_context': 'Product Category'
        });
        let t = await this.context.getToken();

        getAssetBlob(url, t)
            .then(response => {
                if (!response.ok) {
                    this.setState({
                        error: { header: "Unexpected download problem", body: response.data }
                    });
                }
                // IE - EDGE hack
                else if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    let fileName = url.split("/").pop();
                    window.navigator.msSaveOrOpenBlob(response.data, fileName);
                } else {
                    let url = URL.createObjectURL(response.data);
                    window.open(url, "_blank");
                }
            });
    }

    openAndLoadLink = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    render() {
        const { content, isVisible } = this.props;
        const config = {
            ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'p', 'li', 'ul', 'ol']
        };

        const unitSizes = content.gpheUnitSizes.map((size) => <div className="sizes">{size}</div>);
        const availableSuppliers = content.availableFrom.map((supplier) => <div className="supplier">{supplier}</div>);
        const onRequest = content.onRequest.length === 0 ? (<div className="supplier">N/A</div>) : (content.onRequest.map((supplier) => (<div className="supplier">{supplier}</div>)));

        const addonExamples = content.assets.filter(asset => asset.documentTaxonomy.assetSubType === "Add-on example");
        const assetLinks = content.assets.filter(asset => asset.documentTaxonomy.assetSubType !== "Add-on example");
        const links = content.links;

        const description = DOMpurify.sanitize(content.description, config);
        const input = DOMpurify.sanitize(content.input, config);
        const deliverables = DOMpurify.sanitize(content.deliverables, config);
        const limitations = DOMpurify.sanitize(content.limitations, config);
        const dependencies = DOMpurify.sanitize(content.dependencies, config);

        return (
            <>
                {isVisible && (
                    <tr className={this.props.levelClass}>
                        <th scope="row"></th>
                        <td className="tableDiv" colSpan="1">
                            <h6><b>{content.name}</b></h6>
                            <div>{unitSizes}</div>
                            <div>
                                <div className="cost">Cost applied for: <b>{content.costAppliedFor}</b></div>
                                <div className="cost">{content.moduleVariant ? content.moduleVariant : 'ModuleVariant: N/A'}</div>
                                <div className="cost">{content.extraType ? content.extraType : 'ExtraType: N/A'}</div>
                            </div>
                            <div>
                                <h6><b>AVAILABLE FROM:</b></h6>
                                <div className="extrasIndent">{availableSuppliers}</div>
                            </div>
                            <div>
                                <h6><b>ON REQUEST FROM:</b></h6>
                                <div className="extrasIndent">{onRequest}</div>
                            </div>
                            <div>
                                <h6><b>DESCRIPTION</b></h6>
                                <div className="extrasIndent" dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                            <div>
                                <h6><b>INPUT</b></h6>
                                <div className="extrasIndent" dangerouslySetInnerHTML={{ __html: input }} />
                            </div>
                            <div>
                                <h6><b>DELIVERABLES</b></h6>
                                <div className="extrasIndent" dangerouslySetInnerHTML={{ __html: deliverables }} />
                                {addonExamples.map(example => (
                                    <a className="extrasLinks" onClick={() => this.handleAssetClick(example.urls.privateUrl || example.urls.publicUrl)} rel="noopener noreferrer" target="_blank">
                                        {example.displayName}
                                    </a>
                                ))}
                            </div>                            
                            <div>
                                <h6><b>LIMITATIONS</b></h6>
                                <div className="extrasIndent" dangerouslySetInnerHTML={{ __html: limitations }} />
                            </div>
                            <div>
                                <h6><b>DEPENDENCIES</b></h6>
                                <div className="extrasIndent" dangerouslySetInnerHTML={{ __html: dependencies }} />
                            </div>
                            <div>
                                <h6><b>LINKS</b></h6>
                                {assetLinks.map(asset =>
                                    asset.urls.privateUrl || asset.urls.publicUrl ? (
                                        <div>
                                            <button className="extrasButton" onClick={() => this.openAndLoadLink(asset.urls.privateUrl || asset.urls.publicUrl)}>
                                                {asset.displayName}
                                            </button>
                                        </div>
                                    ) : null

                                )}

                                {links.map(link =>
                                    link.urls.privateUrl || link.urls.publicUrl ? (
                                        <div>
                                            <button className="extrasButton" onClick={() => this.openAndLoadLink(link.urls.privateUrl || link.urls.publicUrl)}>
                                                {link.name}
                                            </button>
                                        </div>
                                    ) : null
                                )}
                            </div>
                        </td>
                        <th scope="row">
                        </th>
                    </tr>
                )}
            </>
        );
    }
}

ExtrasContent.contextType = APIContext;